<template>
  <div id="forgot-password-email-confirmation">
    <auth-forms-wrapper show-links width="392px" card-classes="px-0">
      <template #form>
        <v-row>
          <v-col align="center">
            <v-img
              contain
              max-width="173px"
              max-height="160px"
              :aspect-ratio="10 / 9"
              alt="Forgot Password Email Sent."
              :src="$images.forgotPasswordEmailSent"
              id="forgot-password-email-confirmation-image"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center">
            <h3
              v-text="'Email Sent!'"
              class="pb-2 font-weight-large secondary--font text--primary"
            />
            <p
              class="body-2 login-grey--text mb-0"
              v-text="
                `You are all set, please check your inbox and follow the instructions to reset your password.`
              "
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col id="login-link" class="pt-6 d-flex justify-center">
            <router-link
              to="/login"
              id="back-to-login-button"
              class="text-decoration-none"
            >
              <span
                class="subtitle-2 text--secondary font-weight-bold"
                v-text="'Back to Login'"
                v-track="'back-to-login-btn'"
              />
            </router-link>
          </v-col>
        </v-row>
      </template>
    </auth-forms-wrapper>
  </div>
</template>

<script>
import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";

/**
 * Forgot password email confirmation sent page
 * @author {Jatin Kamboj}
 */
export default {
  name: "ForgotPasswordConfirmation",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { AuthFormsWrapper },
};
</script>
